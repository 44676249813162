/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import React, { useLayoutEffect, useState, useEffect } from "react"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useMedia } from "react-use"

import { renderLineBreak, findBySlug } from "../helpers"

import Carousel from "../components/carousel"
import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import Hero from "../components/tahoe-campaign-hero"
import Row from "../components/row"
import Text from "../components/text"
import plantPrefabLogo from "../images/PP-logo-white-sm (1).svg"
import phoneicon from "../images/phone-icon.svg"
import emailicon from "../images/email-icon.svg"
import certified from "../images/2018-B-Corp-Logo-White.svg"
import PageLink from "../components/page-link"
import RichText from "../components/rich-text"
import { useIsClient } from "../hooks/use-is-client"

const TahoeCampaign = ({ data }) => {
  const [innerwidth, setinnerwidth] = useState(null)
  const [availablerwidth, setAvailableWidth] = useState(null)
  const [mediaQueryMatch, setmediaQueryMatch] = useState(false)
  const isClient = useIsClient()
  if (isClient)
    window.addEventListener("resize", () => {
      setAvailableWidth(window.screen.availWidth)
      setinnerwidth(window.innerWidth)
    })
  useEffect(() => {
    if (isClient) {
      setAvailableWidth(window.screen.availWidth)
      setinnerwidth(window.innerWidth)
    }
  }, [isClient])

  useEffect(() => {
    if (availablerwidth > innerwidth) {
      if (innerwidth < 992) {
        setmediaQueryMatch(true)
        return
      }
    } else {
      if (availablerwidth < 992) {
        setmediaQueryMatch(true)
        return
      }
    }
    if (availablerwidth > innerwidth) {
      if (innerwidth >= 992) {
        setmediaQueryMatch(false)
        return
      }
    } else {
      if (availablerwidth >= 992) {
        setmediaQueryMatch(false)
        return
      }
    }
  }, [innerwidth, availablerwidth])

  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "tahoe-campaign-or-hero-module",
    })
    const heroHeights = [null, null, 549]

    if (!heroBladePatternData) return
    // TODO: Stacked mobile hero
    const ctaList = []

    if (heroBladePatternData.ctaTitle) {
      ctaList.push({
        link: heroBladePatternData?.contentCards[0]?.ctaDestination,
        type: "secondary",
        text: heroBladePatternData?.contentCards[0]?.ctaTitle,
      })
    }

    return (
      <>
        <Hero
          customStyle={{
            height: heroHeights,
            maxHeight: heroHeights,
          }}
          background={{
            type: "image",
            source: {
              mobileImage: heroBladePatternData?.contentCards[0]?.mobileImage,
              desktopImage: heroBladePatternData?.contentCards[0]?.desktopImage,
            },
          }}
          title={heroBladePatternData?.contentCards[0]?.title}
          body="20-50% faster than site-built with superior craftsmanship and cost control."
          ctaList={ctaList}
          type="secondary"
          textBackground="#31aabb"
        />
      </>
    )
  }
  const renderLetsTalk = () => {
    const designMattersBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "tahoe-campaign-or-lets-talk",
    })

    if (!designMattersBladePatternData) return
    const description = get(
      designMattersBladePatternData,
      "contentCards[0].description"
    )

    return (
      <div
        sx={{
          width: "100%",
          overflow: "hidden",
          mb: [50, null, null, null],
        }}
      >
        <Container
          customStyle={{
            backgroundColor: theme => [
              theme.colors.backgrounds.primary,
              theme.colors.white,
            ],
            py: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.md,
            ],
            // maxWidth: [null, null, containerMaxWidth, null],
          }}
        >
          <Row customStyle={{ px: [0, 0, null, null] }}>
            <Column
              size={[12, 12, 4]}
              customStyle={{
                mb: theme => [theme.spacing.vertical.sm, null],
              }}
            >
              <Text type="h2" customStyle={{ marginBottom: "20px" }}>
                {renderLineBreak(designMattersBladePatternData.title)}
              </Text>
              <div>
                <img src={phoneicon} sx={{ width: "35px" }} />
                <Text
                  type="h5"
                  customStyle={{
                    color: "#31aabb",
                    marginBottom: "10px",
                    display: "inline-block",
                    position: "relative",
                    top: "-9px",
                    marginLeft: "10px",
                  }}
                >
                  <a href="tel:909.546.7444"> 909.546.7444</a>
                </Text>
              </div>

              <div>
                <img src={emailicon} sx={{ width: "35px" }} />
                <Text
                  type="h5"
                  customStyle={{
                    color: "#31aabb",
                    marginBottom: "10px",
                    display: "inline-block",
                    position: "relative",
                    top: "-9px",
                    marginLeft: "10px",
                  }}
                >
                  <a href="mailto:info@plantprefab.com"> Email Us</a>
                </Text>
              </div>
            </Column>
            <Column size={[12, 12, 8, 7]}>
              {description ? (
                <Text data={description} />
              ) : (
                <>
                  <Text
                    type="p"
                    customStyle={{
                      mt: theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        0,
                      ],
                    }}
                  >
                    {designMattersBladePatternData.description.description}
                  </Text>
                  <div
                    sx={{
                      mt: theme => [20, 20, theme.spacing.vertical.md],
                    }}
                  >
                    <CTA
                      link="/contact-b2b"
                      type="secondary"
                      linkType="internal"
                    >
                      Submit a Project Inquiry
                    </CTA>
                  </div>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  const renderProjects = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "tahoe-campaign-or-featured-projects-module",
    })
    if (!heroBladePatternData.contentCards) return
    return (
      <div>
        <Carousel type="quaternary">
          {heroBladePatternData.contentCards.map(contentCard =>
            !mediaQueryMatch ? (
              <div
                key={kebabCase(contentCard.title)}
                sx={{
                  height: [null, null, null, 650],
                  background: `url(${contentCard.desktopImage.file.url})`,
                }}
              >
                <div
                  sx={{ position: "relative", width: "100%", height: "100%" }}
                >
                  <div
                    sx={{
                      position: "absolute",
                      // left: [null, null, null,"0%",,"6%", "14%"],
                      left: "15%",
                      width: "14%",
                      bottom: "0px",
                      "@media screen and (max-width: 1900px)": {
                        left: "14%",
                        width: "calc(20px + 12vw)",
                      },
                      "@media screen and (max-width: 1800px)": {
                        left: "12%",
                        width: "calc(20px + 13vw)",
                      },
                      "@media screen and (max-width: 1700px)": {
                        left: "10%",
                        width: "calc(20px + 14vw)",
                      },
                      "@media screen and (max-width: 1600px)": {
                        left: "calc(100vw/12)",
                        width: "calc(20px + 15vw)",
                      },
                      "@media screen and (max-width: 1500px)": {
                        left: "5%",
                        width: "calc(20px + 14vw)",
                      },
                      "@media screen and (max-width: 1450px)": {
                        left: "2%",
                        width: "calc(20px + 16vw)",
                      },
                      "@media screen and (max-width: 1350px)": {
                        left: "0px",
                        width: "calc(20px + 16vw)",
                      },
                      "@media screen and (max-width: 1267px)": {
                        left: "0px",
                        width: "calc(5px + 20vw)",
                      },
                      "@media screen and (max-width: 1089px)": {
                        left: "0px",
                        width: "calc(5px + 21vw)",
                      },
                      "@media screen and (max-width: 1023px)": {
                        left: "0px",
                        width: "calc(5px + 23vw)",
                      },
                    }}
                  >
                    <RichText
                      textStyle={{
                        marginBottom: "0px",
                        // padding: "21px 23px",
                        padding: "21px calc(5px + 1vw)",
                        backgroundColor: "#31aabb",
                        color: "#ffffff",
                        width: "fit-content",
                      }}
                      data={contentCard.description}
                    ></RichText>
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={kebabCase(contentCard.title)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img src={contentCard.desktopImage.file.url} />

                <RichText
                  textStyle={{
                    // marginBottom: "0px",
                    padding: "21px 23px",
                    // backgroundColor: "#31aabb",
                    color: "#000000",
                  }}
                  data={contentCard.description}
                ></RichText>
              </div>
            )
          )}
        </Carousel>
      </div>
    )
  }

  const renderBenefits = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "tahoe-campaign-or-benefits-module",
    })

    return (
      <Container
        customStyle={{
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
          // maxWidth: [null, null, containerMaxWidth, null],
        }}
      >
        <Row customStyle={{ p: [0, 0, 2] }}>
          {heroBladePatternData.contentCards.map(contentCard => (
            <Column
              customStyle={{
                mt: theme => [0, 0, 0],
                mb: [20, 20, 0],
                pr: theme => [null, null, 20, 50],
              }}
              size={[12, 12, 4]}
              key={kebabCase(contentCard.title)}
            >
              <Text type="h2" customStyle={{ color: "#31aabb" }}>
                {contentCard.title}
              </Text>

              {contentCard.description &&
                documentToReactComponents(contentCard.description.json, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <Text
                        customStyle={{
                          mt: theme => [20, theme.spacing.vertical.sm],
                          fontSize: 15,
                        }}
                      >
                        {children}
                      </Text>
                    ),
                  },
                })}
            </Column>
          ))}
        </Row>
      </Container>
    )
  }

  const renderSupport = () => {
    return (
      <div sx={{ backgroundColor: "#286c7f" }}>
        <Container
          customStyle={{
            py: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Row customStyle={{ px: [0, 0, null, null] }}>
            <Column
              customStyle={{
                mt: theme => [50, 50, 0],
              }}
              size={[12, 12, 6, 5]}
              // key={kebabCase(contentCard.title)}
            >
              <Text type="h2" customStyle={{ color: "#fff" }}>
                See how Plant Prefab can support your project.
              </Text>
              <div
                sx={{
                  mt: theme => [20, 20, theme.spacing.vertical.sm],
                  display: "inline",
                }}
              >
                <CTA
                  link="/contact-b2b"
                  type="secondary"
                  linkType="internal"
                  customStyle={{
                    my: ["20px", "20px", null],
                    mr: ["10px"],
                    fontSize: "15px",
                  }}
                >
                  Contact Us
                </CTA>
                {mediaQueryMatch && <br />}
                <Text
                  type="h5"
                  customStyle={{
                    display: "inline",
                    color: "#31aabb",
                    marginLeft: [null, null, null, "10px"],
                   
                  }}
                >
                  <a
                    href="tel:909.546.7444"
                  >
                    {" "}
                    909.546.7444
                  </a>
                </Text>
                <Text
                  type="h5"
                  customStyle={{
                    display: "inline",
                    color: "#31aabb",
                    marginLeft: ["10px", null, null, "10px"],
                    borderLeft: "1px solid #31aabb",
                    paddingLeft:["4px","0px",null,"4px"]
                  }}
                >
                  <a href="mailto:info@plantprefab.com"> Email Us</a>
                </Text>
              </div>
            </Column>
            <Column size={[0, 0, 1, 3]}></Column>
            <Column
              customStyle={{
                marginRight: 0,
                marginLeft: "auto",
                margin: "auto",
                mt: theme => [20, 20, 20, 0],
                display: "inline-block",
              }}
              size={[12, 12, 5, 4]}
            >
              <Row customStyle={{ px: [0, 0, null, null] }}>
                <Column size={[12, 12, 6]}>
                  <PageLink link="/" type="internal">
                    <img
                      sx={{
                        height: "90px",
                        width: "160px",
                        m: 0,
                      }}
                      src={plantPrefabLogo}
                      alt="Plant Prefab Logo"
                    />
                  </PageLink>
                </Column>
                <Column
                  size={[0, 0, 0, 1]}
                  customStyle={{ display: ["none", null, "block", "block"] }}
                >
                  <div
                    sx={{ borderLeft: "1px solid #fff ", height: "100px" }}
                  ></div>
                </Column>
                <Column size={[12, 12, 4]}>
                  <img
                    sx={{
                      height: [160, 160, 100, 100],
                      width: ["100px", "100px", "auto", 250],
                      m: 0,
                      mt: [10, 10, 0, 0],
                    }}
                    src={certified}
                    alt="Plant Prefab Logo"
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div>
      {renderHeroBladePattern()}
      {renderLetsTalk()}
      {renderProjects()}
      {renderBenefits()}
      {renderSupport()}
    </div>
  )
}

export default TahoeCampaign

export const query = graphql`
  query tahoeCampaign {
    contentfulPage(slug: { eq: "tahoe-campaign-landing-page" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
